const PATH = {
  HOME: "/home",
  ABOUT: "/about",
  MEMBERSHIP: "/membership",
  NEW_REQUEST_CREATE: "/newrequest/create",
  ITR_NOTICE_PATH: "/service/itr-notice",
  SERVICE_TAX_APPEAL: "/service/tax-appeal",
  GST_NOTICE: "/service/gst-notice",
  SERVICE_COSULTATION: "/service/consultation",
  SERVICE_BUSINESS_CONSULTATION: "/service/business-consultation",
  SERVICE_ITR_FILING: "/service/filing-itr",
  SERVICE_GST_RETURN: "/service/gst-return",
  SERVICE_TDS_TCS_FILING: "/service/filing-tds-tcs",
  SERVICE_VIDEO_CONSULTATION: "/service/video-consultation",
  CHECKOUT: "/checkout",
  LATEST_NEWS: "/latest-news",
  CONTACT_US: "/contact",
  REGISTER: "/register",
  CREATE_USER: "/admin/user/create",
  CREATE_ADVOCATE: "/admin/advocate/create",
  TICKET_DETAILS: "admin/requests/details/:id",
  ADVOCATE_LIST: "/admin/psp",
  TICKET_REQUEST_LIST: "/admin/requests",
  LOGIN: "/login",
  PRIVACY_POLICY: "/privacypolicy",
};

export { PATH };
