const FIELD_NAME = {
  NAME: "name",
  EMAIL: "email",
  SECTION: "section",
  PRICE: "price",
  UPLOAD_GST: "uploadGSTNotice",
  PHONE_NUMBER: "phoneNumber",
};

export { FIELD_NAME };
