export const REGISTER_CATEGORIES = [
  {
    key: 1,
    name: "CA / CMA / CS / TAX ADVOCATES",
    value: 9,
  },
  {
    key: 2,
    name: "DEFENCE PERSONNELS",
    value: 6,
  },
  {
    key: 3,
    name: "DIFFERENTALY ABLED",
    value: 3,
  },
  {
    key: 4,
    name: "GENERAL",
    value: 1,
  },
  {
    key: 5,
    name: "INTERNATIONAL SPORTS PERSONNELS",
    value: 4,
  },
  {
    key: 6,
    name: "MEDIA / NEWSPAPER JOURNALISTS",
    value: 7,
  },
  {
    key: 7,
    name: "START-UPS",
    value: 5,
  },
  {
    key: 8,
    name: "THIRD GENDER",
    value: 8,
  },
  {
    key: 9,
    name: "WORKING WOMEN",
    value: 2,
  },
];
