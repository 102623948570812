import React, { useState }  from "react";
import { Card, Space, Statistic, Row, Col } from "antd";
import { ClusterOutlined } from "@ant-design/icons";
import ListHeader from "../../../../common/ListHeader/ListHeader";
import { getAllTickets } from "../../../../services/ticket.service";
import FixMyTaxTable from "../../../../common/Table/FixMyTaxTable";
import {
  getKeyFromObject,
} from "../../../../shared/utils";
import { TicketStatus } from "../../../../shared/constants";

import "./DashBoard.less";

const DashBoard = () => {

  const [responseInfo, setResponeInfo] = useState({ loading: false, data: [] });
  const [groupByStatus, setgroupByStatus] = useState({data:[]});
  const [groupBySectionValue, setgroupBySectionValue] = useState({data: []});
  const [groupByAssignedTo, setgroupByAssignedTo] = useState({data: []});
  const aggregateDataForDashboard = (tickets) => {
    let groupByStatus = {}
    let groupBySection = {}
    let groupByAssignedTo = {}
    for(let item of tickets){
      if(!groupByStatus.hasOwnProperty(item.status)){
        groupByStatus[item.status] = 1
      }
      else{
        groupByStatus[item.status] += 1
      }
      if(!groupBySection.hasOwnProperty(item.section)){
        groupBySection[item.section] = 1
      }
      else{
        groupBySection[item.section] += 1
      }
      if(!groupByAssignedTo.hasOwnProperty(item.assignedUserName)){
        groupByAssignedTo[item.assignedUserName] = 1
      }
      else{
        groupByAssignedTo[item.assignedUserName] += 1
      }
    }
    return {
      groupByStatus,
      groupBySection,
      groupByAssignedTo
    }
  }
  const convertDictionarytoList = (obj) => {
    let res = [] 
    for(let key in obj){
      res.push({
        key,
        value: obj[key]
      })
    }
    return res
  }
  React.useEffect(() => {
    (async () => {
      try {
        setResponeInfo((prevState) => ({
          ...prevState,
          loading: true,
        }));
        const res = await getAllTickets();
        let { groupByStatus, groupBySection, groupByAssignedTo } = aggregateDataForDashboard(res);
        groupBySection = convertDictionarytoList(groupBySection)
        groupByStatus = convertDictionarytoList(groupByStatus)
        groupByAssignedTo = convertDictionarytoList(groupByAssignedTo)
        console.log("groupBySection", groupBySection.length)

        setgroupByStatus((prevState) => ({
          ...prevState,
          data: groupByStatus
        }))
        setgroupBySectionValue((prevState) => ({
          ...prevState,
          data : groupBySection
        }))
        setgroupByAssignedTo((prevState) => ({
          ...prevState,
          data : groupByAssignedTo
        }))
        setResponeInfo((prevState) => ({
          ...prevState,
          data: res,
          loading: false,
        }));
      } catch (e) {
        console.error(e);
      } finally {
        setResponeInfo((prevState) => ({
          ...prevState,
          loading: false,
        }));
      }
    })();
  }, []);
  const columns = [
    {
      title: 'Section Type',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'No of Tickets',
      dataIndex: 'value',
      key: 'value',
    }
  ];
  function DahboardCard({title,value}) {
    return(
      <Space direction="horizontal">
          <Card style={{ width: 300 }}>
            <Space direction="horizontal">
              {/* <ShoppingCardOutlined/> */}
              <Statistic title={title} value={value}/>
            </Space>
          </Card>
        </Space>
    )
  }
  return (
    <Card className="dashboard-card-container">
      <ListHeader leftContent={<h2>DashBoard</h2>}></ListHeader>
      <h4>TOTAL TICKETS</h4><br/>
      <Row style={{
        justifyContent: "center"
      }}>
         <Col
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flex: 5
            }}>
              <DahboardCard title="Total Orders" value={responseInfo.data.length}/>
            </Col>
      </Row>

      <h4>STATUSES</h4>
      <Row style={{
        justifyContent: "center"
      }}>
        {groupByStatus.data.map((x)=>{
          return (
            <Col
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flex: 5
            }}>
              <DahboardCard title={getKeyFromObject(TicketStatus, Number(x.key))} value={x.value}/>
            </Col>
          )
        })}   
      </Row> 
      <h4>SSPs</h4>
      <Row  style={{
      justifyContent: "center"
    }}>
      
        {groupByAssignedTo.data.map((x)=>{

          return(
            <Col
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flex: 1
            }}>
              <DahboardCard title={x.key || 'NA'} value={x.value}/>
            </Col>
          ) 
        })}
      </Row>
      <h4>NATURE OF TICKETS</h4>
      <div className="content-padding dashboard-card-content">
      
        <Row style={{ marginTop: 20 }}>
        
            <FixMyTaxTable
              size="small"
              columns={columns}
              dataSource={groupBySectionValue.data}
              loading={responseInfo.loading}
            />
        
        </Row>
      </div>
    
    </Card>
  );
};

export default DashBoard;
